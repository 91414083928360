import React from 'react';
import './Page.css';

const Page7 = () => (
  <div className="page">
   
    <p className="" style={{bottom:'55%',fontFamily:'Great Vibes',fontSize:'45px',color:'white'}}><b>Thank you for coming. </b></p>

    <p className="" style={{bottom:'55%',fontFamily:'Great Vibes',fontSize:'45px',color:'white'}}><b>Let's make it a night to remember</b></p>


    <p className='Swipeleft'><b> Swipe left ! </b></p>  
  </div>
);

export default Page7;
