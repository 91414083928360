import React from 'react';
import './Page.css';

const Page5 = () => (
  <div className="page">
    <h1 className="" style={{fontSize:'45px' , fontFamily:'Great Vibes', color:'white'}}>Gift Registry</h1><br></br>
    <p style={{fontSize:'30px',fontFamily:'futura', color: 'white'}}><br/><br/>
      The greatest gift we could have is<br></br>
      your presence at our wedding .<br></br>
      However, a wedding list will <br></br>
      be available at <br></br><br></br>
      <b>Whish Money</b><br></br>
      <p style={{fontSize:'30px',bottom:'40%'}}><b>No.20559728</b></p><br></br> </p>
      <p className='Swipeleft' style={{bottom:'10%'}}><b> Swipe left ! </b></p>
  </div>
);

export default Page5;
