import React from 'react';
import './Page.css';

const Page2 = () => (
  <div className="page">
    <h1 className="text" style={{fontSize:'45px'}}>Save The Date</h1><br></br>
    <p className="text" style={{bottom:'55%',fontSize:'33px'}}>
        With joyous hearts, <br></br>
        Mteiny & Helou families invite you to<br></br>
        celebrate the wedding of </p><br></br>
        <p className='absolute-text2' style={{bottom:'35%',fontSize:'55px'}}><b>Georges & Marie Li</b></p> <br></br><br></br>
       <p className='Onaugust312024' style={{bottom:'30%',fontSize:'33px'}}> On august 31,2024</p>

        <p className='Swipeleft'><b> Swipe left ! </b></p>
  </div>
);

export default Page2;