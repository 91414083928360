import React from 'react';
import './Page.css';

const Page1 = () => (
  <div className="page">
    <h1 className=""  style={{color:'white', fontFamily:'Great Vibes', fontSize:'70px',bottom:'40%'}}>Georges</h1>
    <h1 className="" style={{color:'white', fontFamily:'Great Vibes', fontSize:'60px'}} >&</h1><br></br>
    <h1 className=""  style={{color:'white', fontFamily:'Great Vibes', fontSize:'70px',bottom:'30%'}} >Marie Li</h1>
    <h2 className="aregettingmarried" style={{bottom:'27%',fontSize:'45px'}}><b>Are getting married</b></h2>
    


    <p className='Swipeleft'><b> Swipe left ! </b></p>
  </div>
);

export default Page1;
